<template>
  <div class="icon-paragraph" ref="body">
    <span class="material-icons" :style="getStyleObject()">{{
      data.field_icon[0].value
    }}</span>
    <div class="text-container">
      <h4 :style="getStyleObject()">{{ data.title[0].value }}</h4>
      <p
        :style="getStyleObject()"
        v-html="sanitise(data.field_text[0].value)"
      ></p>
    </div>
  </div>
</template>

<script>
import { visualMixin } from "@/mixins/visualMixin.js";
import { httpMixin } from "@/mixins/httpMixin.js";
import ScrollMagic from "scrollmagic";

export default {
  mixins: [visualMixin, httpMixin],

  props: {
    data: Object
  },

  mounted: function() {
    new ScrollMagic.Scene({
      triggerElement: this.$refs.body,
      offset: 0,
      triggerHook: 0.8,
      reverse: false
    })
      .setClassToggle(this.$refs.body, "icon-paragraph--visible")
      .addTo(this.animationController);
  },

  methods: {
    getStyleObject() {
      return {
        "transition-delay": Math.round(Math.random() * 900, 0) + "ms"
      };
    }
  }
};
</script>

<style scoped>
.icon-paragraph {
  margin: 24px 24px 36px;
  padding: 24px 12px 0;
  display: grid;
  grid-template-rows: auto 1fr;
  text-align: left;
}

.icon-paragraph > .material-icons {
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
}
.icon-paragraph--visible > .material-icons {
  opacity: 1;
}

.text-container > h4 {
  border-bottom: solid 4px #484848;
  padding-bottom: 24px;
  clip-path: polygon(0 0, 0 100%, 0 100%, 0 0);
  transition: clip-path 0.8s ease-in-out;
}
.icon-paragraph--visible > .text-container > h4 {
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
}

.material-icons {
  color: #fff;
  font-size: xxx-large;
  background: transparent;
}

.text-container {
  padding: 8px 0 24px;
}

.text-container > p {
  text-align: left;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  transition: clip-path 0.8s ease-in-out;
}

.icon-paragraph--visible > .text-container > p {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

@media screen and (min-width: 600px) {
  .icon-paragraph {
    margin: 80px 24px 140px;
    padding: 24px 12px 0;
  }
}
</style>
